<template>
  <div class="scoretable" v-if="currentList && currentList.length">
    <div class="scoretable-background">
      <img src="/images/scoretable/scoretable-bg.jpg" />
    </div>
    <div class="scoretable-detail">
      <div class="scoretable-logo wow fadeInLeft" data-wow-delay="0.3s">
        <span class="week-info">{{ currentWeek }}. HAFTA</span>
      </div>
      <div class="scoretable-matches">
        <div class="scoretable-item">
          <div class="row table-head wow fadeInUp" data-wow-delay="1.2s">
            <div class="col-md-1 offset-md-6">
              <strong>O</strong>
            </div>
            <div class="col-md-1">
              <strong>G</strong>
            </div>
            <div class="col-md-1">
              <strong>B</strong>
            </div>
            <div class="col-md-1">
              <strong>M</strong>
            </div>
            <div class="col-md-1">
              <strong>Av</strong>
            </div>
            <div class="col-md-1">
              <strong>P</strong>
            </div>
          </div>
          <div v-for="(item, index) in currentList" :key="item.id" class="row table-list wow fadeInUp"
            data-wow-delay="1.5s">
            <div class="col-md-1 border-line">
              <span>{{ pageNo * 6 + index + 1 }}</span>
            </div>
            <div class="col-md-1">
              <img class="scoretable-item-logo effect2" :src="'/images/fixture/superlig/' + item.teamImage" />
            </div>
            <div class="col-md-4 border-line">
              <strong>{{ item.teamName }}</strong>
            </div>
            <div class="col-md-1 border-line">
              <span>{{ item.playedMatchCount }}</span>
            </div>
            <div class="col-md-1 border-line">
              <span>{{ item.winCount }}</span>
            </div>
            <div class="col-md-1 border-line">
              <span>{{ item.drawCount }}</span>
            </div>
            <div class="col-md-1 border-line">
              <span>{{ item.lossCount }}</span>
            </div>
            <div class="col-md-1 border-line">
              <span>{{ item.average }}</span>
            </div>
            <div class="col-md-1">
              <span>{{ item.score }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
import store from "../store/index";
new WOW().init();
export default {
  name: "scoreboard",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false,
      pageNo: 0
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (this.destroyed) return;
        this.pageNo += 1;
        if (this.pageNo * 6 >= this.allList.length)
          store.dispatch("setNextPlaylist");
        else this.startTimer();
      }, 10000);
    }
  },
  computed: {
    model() {
      return this.$store.state.scoreboard;
    },
    allList() {
      return this.model.list || [];
    },
    currentList() {
      return this.allList.slice(this.pageNo * 6, this.pageNo * 6 + 6);
    },
    currentWeek() {
      if (!this.allList || !this.allList.length) return 1;
      return Math.max(...this.allList.map(u => u.playedMatchCount));
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";
@import "../assets/animate/style.css";

.scoretable-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }
}

.scoretable {
  text-align: center;
  padding-top: 22px;
}

.scoretable-detail {
  text-align: center;
  display: inline-block;
  position: relative;
  max-width: 1270px;
  width: 100%;
  height: auto;
  font-family: Montserrat-SemiBold;
  color: white;
  text-transform: uppercase;

  .scoretable-logo {
    background: url("/images/scoretable/scoretable-logo-bg.png") center top no-repeat;
    background-size: 100%;
    width: 704px;
    height: 278px;

    .week-info {
      position: absolute;
      left: 480px;
      top: 7px;
      font-size: 42px;
      line-height: 46px;
      color: white;
    }
  }

  .scoretable-item {
    .nopadding {
      padding: 0;
    }

    strong {
      font-family: Montserrat-SemiBold;
      font-size: 48px;
      line-height: 78px;
      line-height: 78px;
      height: 78px;
      -webkit-line-clamp: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    span {
      font-family: Montserrat-Light;
      font-size: 50px;
      line-height: 78px;
      letter-spacing: -2px;
    }

    .scoretable-item-logo {
      width: 100%;
      height: 52px;
      object-fit: contain;
      object-position: center;
      margin: 13px 0 13px 8px;
    }

    .table-head {
      &>* {
        padding: 0;
      }
    }

    .table-list {
      border: solid 0.1rem rgba(255, 255, 255, 0.1);
      background-color: rgba(71, 103, 13, 0.6);
      padding: 5px 0;
      border-bottom: none;

      div {
        position: relative;

        &.border-line {
          &:before {
            content: "";
            width: 1px;
            height: 90%;
            position: absolute;
            background: white;
            right: 0;
            top: 5%;
            border-radius: 6px;
            opacity: 0.2;
          }
        }
      }

      &>* {
        padding: 0;
      }

      &:nth-child(2n + 1) {
        background-color: rgba(97, 127, 19, 0.6);
      }

      &:last-child {
        border-bottom: solid 0.1rem rgba(255, 255, 255, 0.1);
      }
    }
  }
}</style>
